.buttons {
    margin: 20px 0;
}

.explorer-buttons {
    /*display: flex;*/
}

.explorer-buttons>*:not(:first-child) {
    margin-left: 5px !important;
    margin-bottom: 15px;
}

.files {
    margin: 20px 0;
}

.up-button {
    display: block;
    margin: 10px 0;
}

.file-move-portal {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}

.file-move-portal-segment {
    position: relative;
    -webkit-animation: flip-in-hor-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: flip-in-hor-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    font-weight: bold;
    font-size: 1.1em !important;
    /*color: #0055ff;*/
}

/*Generated by Animista*/
@-webkit-keyframes flip-in-hor-bottom {
    0% {
        -webkit-transform: rotateX(80deg);
        transform: rotateX(80deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 1;
    }
}

@keyframes flip-in-hor-bottom {
    0% {
        -webkit-transform: rotateX(80deg);
        transform: rotateX(80deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 1;
    }
}

.user-list {
    display: flex;
    flex-direction: column;
}

.user-list__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.user-list__name {
    font-weight: bold;
}

.user-list__address,
.user-list__country {
    margin-top: 5px;
}

.card-group {
    justify-content: center;
}

.card-grid,
.card {
    padding: 5px 5px !important;
    height: auto !important;
    justify-content: center;
    margin: 1rem;
}

.card-body {
    background-color: #F6F5F5;
    margin: 0;
}

.card-img,
.card-img-top,
.card-img-bottom {
    height: 10rem;
    width: auto;
    align-content: flex-start;
}

.card-text {
    height: fit-content;
    margin-bottom: 2rem;
}

.btn,
.btn-primary {
    position: absolute;
    bottom: 5px;
    color: #263238;
    overflow: auto;
}